import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import ChineseLayout from "../../../components/ChineseLayout"
import Accordion from "../../../components/Accordion"
import DHA from "../../../images/static-only/ingredients/DHA_2.jpg"
import DHAHeader from "../../../images/static-only/ingredients/dha-header.jpg"
import MFGMBody from "../../../images/static-only/ingredients/mfgm-body.jpg"
import Lactoferrin from "../../../images/static-only/ingredients/Lactoferrin.jpg"
import LactoferrinHeader from "../../../images/static-only/ingredients/lactoferrin-header.jpg"
// import Inactivity from "../../../components/Inactivity"

class AccordionListing extends React.Component {
  componentDidMount() {
    Accordion()
    // Inactivity()
  }

  render() {
    return (
      <ChineseLayout>
        <Helmet>
          <title>Meet MJN - 关键成分</title>
        </Helmet>
        <main role="main" className="content-main -content-accordion-listing">

          <section className="hero-area -general -accordion-listing">
            <img src={DHA} alt="Toddler eating food" />

            <div className="hero-area__inner">
              <h1>关键成分</h1>
              <nav className="breadcrumb" role="navigation">
                <Link to="/zh/event-experience" title="史基浦机场活动体验">
                  <i className="fas fa-arrow-left"></i>
                  史基浦机场活动体验
                </Link>
              </nav>
            </div>
          </section>

          <section className="accordion-listing">
            <nav className="breadcrumb -accordion -white" role="navigation">
              <Link to="/zh/event-experience" title="史基浦机场活动体验">
                <i className="fas fa-arrow-left"></i>
                史基浦机场活动体验
              </Link>
            </nav>

            <div className="accordion">
              <div className="accordion__heading menu-button">概览</div>
              <div className="accordion__content">
                <img src={Lactoferrin} alt="Toddler eating food" />                             
                <div className="rich-text">
                  <p>众所周知，婴儿期的良好营养以​​及不同形式的刺激会影响智力发育的各个方面。此外，如果发生某些微量营养素缺乏症或早期营养缺失，则会影响发育。在生命的前两年中，大脑的生长和发育尤其迅速，而营养对于支持这些发展至关重要。</p>
                  <p>营养在帮助新生儿建立胃肠功能方面起着至关重要的作用。母乳可以提供独特的、适合宝宝的必要营养素，以不仅建立消化、吸收和肠屏障功能，也可以建立肠道菌群和免疫耐受性。显然，胃肠道微生物群的发育始于出生，这也是大脑发育的关键时期。最新研究表明，肠道菌群以及可能支持菌群的营养物质可能在大脑成熟中发挥作用，并支持以后的大脑功能。</p>
                  <p>就为发育中的婴儿提供最佳营养而言，母乳显然是“黄金标准”。</p>
                  <p>您可以通过点击不同部分来阅读相关关键成分<sup>*</sup>的信息，以了解有关它们在支持孩子发育方面的益处的更多信息。</p>
                  <p><em><sup>*</sup>免责声明：并非所有美赞臣配方奶粉都包含MFGM，DHA和乳铁蛋白</em></p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">DHA</div>
              <div className="accordion__content">
                <img src={DHAHeader} alt="DHA Header" />                
                <div className="rich-text">
                  <p>DHA是二十二碳六烯酸的简写，是一种omega-3脂肪酸，可在多种食物中找到。 一旦幼儿的母乳或婴儿配方奶粉都含有DHA，婴儿的DHA摄入量可能会下降。</p>
                  <p>在欧洲的婴幼儿中，α-亚麻酸（ALA），二十二碳六烯酸（DHA），铁，维生素D和碘的饮食摄入量较低（在某些欧洲国家），应特别注意确保适当的饮食，尤其要注意确保这些营养素含量不足或有不足风险的婴幼儿适当摄入ALA，DHA，铁，维生素D和碘。</p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">乳脂球膜</div>
              <div className="accordion__content">
                <img src={LactoferrinHeader} alt="" />                
                <div className="rich-text">
                  <p>乳脂球膜（MFGM）由乳腺分泌，以小液滴的形式被MFGM乳脂球膜包裹起来。 它通过乳腺的独特机理释放，由磷脂构成的三层膜结构和胆固醇层，蛋白质和糖蛋白组成。 MFGM是母乳最重要的成分之一。</p>
                  <img src={MFGMBody} alt="MFGM molecule" />
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">乳铁蛋白</div>
              <div className="accordion__content">
                <img src={LactoferrinHeader} alt="" />
                <div className="rich-text">
                  <p>乳铁蛋白是铁结合性蛋白，大量存在于初乳和成熟母乳中，属于转铁蛋白家族。</p>
                </div>
              </div>
            </div>
          </section>

        </main>
      </ChineseLayout>
    )
  }
}

export default AccordionListing